import { env as publicEnv } from '$env/dynamic/public';
import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
const commitSha = import.meta.env?.PUBLIC_VERCEL_GIT_COMMIT_SHA;

if (publicEnv.PUBLIC_SENTRY_DSN_KEY) {
  Sentry.init({
    release: commitSha || 'unknown',
    dsn: publicEnv.PUBLIC_SENTRY_DSN_KEY,
    tracesSampleRate: 1,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [
      new Replay(),
      /* PROFILING START */
      new Sentry.BrowserProfilingIntegration(),
      /* PROFILING END */
    ],
    /* PROFILING START */
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 0.5,
    /* PROFILING END */
    ignoreErrors: [
      // Seems to be related to the Vietnamese Zalo app,
      // see https://github.com/toptal/keycodes/blob/3acbfbe3ae480e825bc7cf4ab043b1174fb1fea5/config/sentry-filter.js#L219
      'zaloJSV2', // cspell:ignore zalo
    ],
  });
}
// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = publicEnv.PUBLIC_SENTRY_DSN_KEY ? handleErrorWithSentry() : undefined;
